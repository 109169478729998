import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoInput from "pungo-ui/PungoInput";
import PungoButton from "pungo-ui/PungoButton";
import { isEmail } from "components/utils/validations";
import { sentContactForm } from "store/actions/contactFormActions";
import { selectSendingLock, saveSendingLock } from "store/slices/sendingLock";
import styles from "./index.module.scss";

const ContactForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailLenght, setEmailLength] = useState(0);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const sendingLock = useSelector(selectSendingLock);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      email: email,
      phone: phoneNumber,
      name: name,
      lastName: lastName,
      company: company,
      message: message,
    };

    dispatch(sentContactForm(data));

    setEmail("");
    setPhoneNumber("");
    setName("");
    setLastName("");
    setCompany("");
    setMessage("");
  };

  const verifyEmail = (e: any) => {
    setEmail(e);
    setEmailLength(e.length);
    if (isEmail(e) && emailLenght>6) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const verifyPhoneNumber = (e: any) => {
    setPhoneNumber(e);
    setIsValidPhone(true);
  };

  const submitDisable = !isValidEmail || !name || !lastName || !company;

  let submitLabel = "";

  useEffect(() => {
    dispatch(saveSendingLock(false));
  }, []);

  if (sendingLock) {
    submitLabel = t("contactForm.sending");
  } else if (submitDisable) {
    submitLabel = t("accountPage.reviewRequiredFields");
  } else {
    submitLabel = t("accountPage.signUp");
  }

  return (
    <div id="contactFormId">
      <PungoSectionCard title={t("ContactSection.title")} description={`${t("ContactSection.description")}`} id="contactUs" distribution="block">
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <PungoInput name={t("ContactSection.name")} value={name} onChange={(e: any) => setName(e)} required="required" />
            <PungoInput name={t("ContactSection.lastName")} value={lastName} onChange={(e: any) => setLastName(e)} required="required" />
            <PungoInput name={t("ContactSection.company")} value={company} onChange={(e: any) => setCompany(e)} required="required" />
            <PungoInput
              name={t("ContactSection.email")}
              value={email}
              onChange={(e: any) => verifyEmail(e)}
              helperText={isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`}
              required="required"
            />
            <PungoInput
              name={t("ContactSection.phone")}
              value={phoneNumber}
              onChange={(e: any) => verifyPhoneNumber(e)}
              helperText={isValidPhone ? undefined : `${t("accountPagHoveredButtone.invalidPhone")}`}
            />
            <PungoInput name={t("ContactSection.message")} value={message} onChange={(e: any) => setMessage(e)} multiline={true} rows={3} />
          </div>
          <div className={styles.submitSection}>
            <div className={styles.submitButton}>
              <PungoButton label={submitLabel} onClick={handleSubmit} color="tertiary" disabled={submitDisable || sendingLock} classNames={styles.submitButtonOver} />
            </div>
          </div>
        </div>
      </PungoSectionCard>
    </div>
  );
};

export default ContactForm;
