import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserRole, selectUserName, selectSurveyStatus } from "store/slices/auth";
import { useTranslation } from "react-i18next";
import AnimationSection from "components/AstroPage/sections/AnimationSection";
import PungoButton from "pungo-ui/PungoButton";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";

import styles from "./index.module.scss";

const PlanSelectionPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [planPaid, setPlanPaid] = useState(false);
  const [renderPage, setRenderPage] = useState(false);

  const userRol = useSelector(selectUserRole)[0];
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);

  const handleGoToPage = (page: string) => {
    if (page === "onlineCalculation") navigate("/calculation/online");
    else if (page === "planDetail") window.open("/plan-info", "_blank", "noopener");
    else if (page === "batchCalculation") navigate("/calculation/batch");
    else if (page === "batchCalculationDemo") navigate("/calculation/batchdemo");
  };

  const txtFreePlan = () => {
    return (
      <div>
        <div className={styles.txt}>
          <p>{`${t("PlanSelect.txtFreePlan.txt01")}`}</p>
          <p>{`${t("PlanSelect.txtFreePlan.txt02")}`}</p>
        </div>
      </div>
    );
  };

  const txtPlan = () => {
    return (
      <div>
        <div>{`${t("PlanSelect.txtPlan")}`}</div>
      </div>
    );
  };

  const txtPlanAccessDetail = (plan: string): string => {
    if (plan.toUpperCase() === "FREE_PLAN") {
      return `${t("PlanSelect.PlanAccessDetail.free")}`;
    } else {
      return `${t("PlanSelect.PlanAccessDetail.noFree")} ${userRol.toUpperCase()}`;
    }
  };

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "free_plan", "pro_plan", "company_user", "company_admin"];

    if (!isLogged) {
      navigate("/login");
    }else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    }else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
      if (userRol !== "free_plan") {
        setPlanPaid(true);
      }
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted]);

  return (
    <>
      {renderPage && (
        <>
          <AnimationSection />
          <div className={styles.planSelection}>
            <div className={styles.title}>Cálculos</div>
            <div className={styles.blocks}>
              <div className={styles.planDescription}>
                {userRol && <div className={styles.txt}>{txtPlanAccessDetail(userRol)}</div>}
                {planPaid ? txtPlan() : txtFreePlan()}
              </div>
              <div className={styles.planOptions}>
                <div className={styles.planList}>
                  <div className={styles.planItem}>
                    <PungoButton onClick={() => handleGoToPage("onlineCalculation")} label={`${t("CalculatorOnline.title")}`} color="secondary" classNames={styles.buttonPlanOptions} />
                  </div>

                  {!planPaid && (
                    <div className={styles.planItem}>
                      <PungoButton
                        onClick={() => handleGoToPage("batchCalculationDemo")}
                        label={`${t("CalculatorBatch.loadData.title")} (Demo)`}
                        color="secondary"
                        classNames={styles.buttonPlanOptions}
                      />
                    </div>
                  )}

                  <div className={styles.planItem}>
                    <PungoButton
                      onClick={() => handleGoToPage("batchCalculation")}
                      label={`${t("CalculatorBatch.loadData.title")}`}
                      color="secondary"
                      classNames={styles.buttonPlanOptions}
                      disabled={!planPaid}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterOnePageSection />
          <PungoSelectLanguage />
        </>
      )}
    </>
  );
};

export default PlanSelectionPage;
