import React from "react";
import { useTranslation } from "react-i18next";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import Corrosion from "./ResultSections/Corrosion";
import Saturation from "./ResultSections/Saturation";
import Criticality from "./ResultSections/Criticality";
import CorrosionOptimization from "./ResultSections/CorrosionOptimization";
import IncrustationOptimization from "./ResultSections/IncrustationOptimization";
import PungoButton from "pungo-ui/PungoButton";
import { Download } from "@mui/icons-material";
import { useAppDispatch } from "app/hooks";
import { downloadResultsReport, downloadResultsReportDemo } from "store/actions/planActions";
import { displaySnackbarAPIError } from "store/actions/utils";
import { selectUserRole } from "store/slices/auth";
import { useSelector } from "react-redux";
import { selectReportReference } from "store/slices/plan";

import styles from "./index.module.scss";

const ResultsProSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reportReference = useSelector(selectReportReference);
  const userRol = useSelector(selectUserRole)[0];

  const requestDownload = () => {
    const plansAccessGrantArray = ["free_plan", "admin", "pro_plan", "company_user", "company_admin"];
    if (plansAccessGrantArray.indexOf(userRol) < 0) {
      dispatch(displaySnackbarAPIError("errorMessage.notVerifiedEmail"));
    } else if (userRol === "free_plan") {
      dispatch(downloadResultsReportDemo());
    } else if (reportReference === -1 ){
      dispatch(displaySnackbarAPIError("errorMessage.invalidPlanUsageType"));
    } else{
      dispatch(downloadResultsReport(reportReference));
    }
  };

  return (
    <>
      <PungoSectionCard title={t("DemoPage.ResultsSection.results")}>
        <div className={styles.container} id="refResultsPro">
          <div className={styles.resultsContainer}>
            <Corrosion />
            <Saturation />
            <Criticality />
            <CorrosionOptimization />
            <IncrustationOptimization />
          </div>
          <div className={styles.resultsDownload}>
            <PungoButton label={`${t("proPlan.downloadResults")}`} type="text" onClick={() => requestDownload()} color="secondary" icon={<Download />} classNames={styles.buttonDownload} />
          </div>
        </div>
      </PungoSectionCard>
    </>
  );
};

export default ResultsProSection;
