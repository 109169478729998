import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoSlider from "../../../../pungo-ui/PungoSlider";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoInput from "pungo-ui/PungoInput";

import styles from "./index.module.scss";
import "../../../../styles/variables.scss";
import PungoButton from "pungo-ui/PungoButton";
import { fetchSimplePlanResults } from "store/actions/planActions";
import { IWellInputData } from "store/slices/plan";

const DataInputSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [wellName, setWellName] = useState<string>(
    `${t("DemoPage.DataInputSection.defaultWellName")}`
  );
  const [oilBarrels, setOilBarrels] = useState<number>(500);
  const [barrelsPerDay, setBarrelsPerDay] = useState<number>(2500);
  const [gasFlow, setGasFlow] = useState<number>(100);
  const [headPressure, setHeadPressure] = useState<number>(200);
  const [headTemperature, setHeadTemperature] = useState<number>(160);
  const [bottomPressure, setBottomPressure] = useState<number>(1500);
  const [bottomTemperature, setBottomTemperature] = useState<number>(250);
  const [pipeDiameter, setPipeDiameter] = useState<number>(3.5);
  const [wellDepth, setWellDepth] = useState<number>(6000);
  const [chlorides, setChlorides] = useState<number>(50000);
  const [coGas, setCoGas] = useState<number>(0.2);
  const [alkalinity, setAlkalinity] = useState<number>(1000);
  const [sodium, setSodium] = useState<number>(1000);
  const [potassium, setPotassium] = useState<number>(500);
  const [magnesium, setMagnesium] = useState<number>(500);
  const [calcium, setCalcium] = useState<number>(2500);
  const [strontium, setStrontium] = useState<number>(50);
  const [barium, setBarium] = useState<number>(50);
  const [sulfates, setSulfates] = useState<number>(200);
  const [acids, setAcids] = useState<number>(100);
  const [anticorrosiveDosage, setAnticorrosiveDosage] = useState<number>(10);
  const [antiscaleDosage, setAntiscaleDosage] = useState<number>(10);
  const [anticorrosivePrice, setAnticorrosivePrice] = useState<number>(10);
  const [antiscalePrice, setAntiscalePrice] = useState<number>(10);

  const wellInputData: IWellInputData = {
    wellName,
    wellProduction: {
      bopd: oilBarrels,
      bwpd: barrelsPerDay,
      mscfd: gasFlow,
      pressureHead: headPressure,
      temperatureHead: headTemperature,
      pressureBottom: bottomPressure,
      temperatureBottom: bottomTemperature,
      pipeDiameter,
      pipeDepth: wellDepth,
    },
    laboratoryAnalysis: {
      chlorine: chlorides,
      co2Fraction: coGas,
      alkalinity,
      sodium,
      potassium,
      magnesium,
      calcium,
      strontium,
      barium,
      sulfate: sulfates,
      carboxylicAcid: acids,
    },
    chemicalsInjection: {
      anticorrosiveDose: anticorrosiveDosage,
      antiscaleDose: antiscaleDosage,
      anticorrosivePrice,
      antiscalePrice,
    },
  };

  const selectAll = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  const getWellProductionSection = () => (
    <div className={styles.parameters}>
      <div className={styles.wellName}>
        <PungoInput
          name={`${t("DemoPage.DataInputSection.wellName")}`}
          value={wellName}
          onChange={setWellName}
          onFocus={selectAll}
        />
      </div>
      <PungoSlider
        minValue={1}
        maxValue={5000}
        step={1}
        defaultValue={oilBarrels}
        description={t("DemoPage.DataInputSection.sliders.oilBarrels")}
        setSelectedValue={setOilBarrels}
        unit="BPPD"
      />
      <PungoSlider
        minValue={1}
        maxValue={5000}
        step={1}
        defaultValue={barrelsPerDay}
        description={t("DemoPage.DataInputSection.sliders.barrelsPerDay")}
        setSelectedValue={setBarrelsPerDay}
        unit="BWPD"
      />
      <PungoSlider
        minValue={1}
        maxValue={1000}
        step={1}
        defaultValue={gasFlow}
        description={t("DemoPage.DataInputSection.sliders.gasFlow")}
        setSelectedValue={setGasFlow}
        unit="MSCFD"
      />
      <PungoSlider
        minValue={14.5}
        maxValue={500}
        step={0.5}
        defaultValue={headPressure}
        description={t("DemoPage.DataInputSection.sliders.headPressure")}
        setSelectedValue={setHeadPressure}
        unit="psi"
      />
      <PungoSlider
        minValue={68}
        maxValue={250}
        step={1}
        defaultValue={headTemperature}
        description={t("DemoPage.DataInputSection.sliders.headTemperature")}
        setSelectedValue={setHeadTemperature}
        unit="F"
      />
      <PungoSlider
        minValue={300}
        maxValue={5000}
        step={1}
        defaultValue={bottomPressure}
        description={t("DemoPage.DataInputSection.sliders.bottomPressure")}
        setSelectedValue={setBottomPressure}
        unit="psi"
      />
      <PungoSlider
        minValue={180}
        maxValue={302}
        step={1}
        defaultValue={bottomTemperature}
        description={t("DemoPage.DataInputSection.sliders.bottomTemperature")}
        setSelectedValue={setBottomTemperature}
        unit="F"
      />
      <PungoSlider
        minValue={2}
        maxValue={5}
        step={0.1}
        defaultValue={pipeDiameter}
        description={t("DemoPage.DataInputSection.sliders.pipeDiameter")}
        setSelectedValue={setPipeDiameter}
        unit="in"
      />
      <PungoSlider
        minValue={1000}
        maxValue={10000}
        step={1}
        defaultValue={wellDepth}
        description={t("DemoPage.DataInputSection.sliders.wellDepth")}
        setSelectedValue={setWellDepth}
        unit="ft"
      />
    </div>
  );
  const getLaboratoryAnalysisSection = () => (
    <div className={styles.parameters}>
      <PungoSlider
        minValue={1000}
        maxValue={100000}
        step={1}
        defaultValue={chlorides}
        description={t("DemoPage.DataInputSection.sliders.chlorides")}
        setSelectedValue={setChlorides}
        unit="mg/l"
      />
      <PungoSlider
        minValue={0.01}
        maxValue={1}
        step={0.01}
        defaultValue={coGas}
        description={t("DemoPage.DataInputSection.sliders.coGas")}
        setSelectedValue={setCoGas}
        unit="fracción"
      />
      <PungoSlider
        minValue={100}
        maxValue={3000}
        step={1}
        defaultValue={alkalinity}
        description={t("DemoPage.DataInputSection.sliders.alkalinity")}
        setSelectedValue={setAlkalinity}
        unit="mg/l"
      />
      <PungoSlider
        minValue={100}
        maxValue={50000}
        step={1}
        defaultValue={sodium}
        description={t("DemoPage.DataInputSection.sliders.sodium")}
        setSelectedValue={setSodium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={100}
        maxValue={5000}
        step={1}
        defaultValue={potassium}
        description={t("DemoPage.DataInputSection.sliders.potassium")}
        setSelectedValue={setPotassium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={100}
        maxValue={5000}
        step={1}
        defaultValue={magnesium}
        description={t("DemoPage.DataInputSection.sliders.magnesium")}
        setSelectedValue={setMagnesium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={100}
        maxValue={10000}
        step={1}
        defaultValue={calcium}
        description={t("DemoPage.DataInputSection.sliders.calcium")}
        setSelectedValue={setCalcium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={1}
        maxValue={100}
        step={0.5}
        defaultValue={strontium}
        description={t("DemoPage.DataInputSection.sliders.strontium")}
        setSelectedValue={setStrontium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={1}
        maxValue={100}
        step={0.5}
        defaultValue={barium}
        description={t("DemoPage.DataInputSection.sliders.barium")}
        setSelectedValue={setBarium}
        unit="mg/l"
      />
      <PungoSlider
        minValue={1}
        maxValue={2000}
        step={1}
        defaultValue={sulfates}
        description={t("DemoPage.DataInputSection.sliders.sulfates")}
        setSelectedValue={setSulfates}
        unit="mg/l"
      />
      <PungoSlider
        minValue={1}
        maxValue={500}
        step={1}
        defaultValue={acids}
        description={t("DemoPage.DataInputSection.sliders.acids")}
        setSelectedValue={setAcids}
        unit="mg/l"
      />
    </div>
  );
  const getChemicalSection = () => (
    <div className={styles.parameters}>
      <div className={styles.note}>
        <Trans>{t("proPlan.labels.chemicalNote")}</Trans>
      </div>
      <PungoSlider
        minValue={0}
        maxValue={20}
        step={0.5}
        defaultValue={anticorrosiveDosage}
        description={t("DemoPage.DataInputSection.sliders.anticorrosiveDosage")}
        setSelectedValue={setAnticorrosiveDosage}
        unit="gal/día"
      />
      <PungoSlider
        minValue={0}
        maxValue={20}
        step={0.5}
        defaultValue={antiscaleDosage}
        description={t("DemoPage.DataInputSection.sliders.antiscaleDosage")}
        setSelectedValue={setAntiscaleDosage}
        unit="gal/día"
      />
      <PungoSlider
        minValue={0}
        maxValue={20}
        step={0.5}
        defaultValue={anticorrosivePrice}
        description={t("DemoPage.DataInputSection.sliders.anticorrosivePrice")}
        setSelectedValue={setAnticorrosivePrice}
        unit="USD/gal"
      />
      <PungoSlider
        minValue={0}
        maxValue={20}
        step={0.5}
        defaultValue={antiscalePrice}
        description={t("DemoPage.DataInputSection.sliders.antiscalePrice")}
        setSelectedValue={setAntiscalePrice}
        unit="USD/gal"
      />
    </div>
  );

  return (
    <PungoSectionCard title={t("DemoPage.DataInputSection.dataInput")}>
      <div className={styles.container} id="refDataInput">
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("DemoPage.DataInputSection.wellProduction")}
            content={getWellProductionSection()}
          />
          <PungoDemoAccordion
            title={t("DemoPage.DataInputSection.laboratoryAnalysis")}
            content={getLaboratoryAnalysisSection()}
          />
          <PungoDemoAccordion
            title={t("DemoPage.DataInputSection.chemicalInyection")}
            content={getChemicalSection()}
          />
          <PungoButton
            label={`${t("manageProfile.calculateButton")}`}
            onClick={() => dispatch(fetchSimplePlanResults(wellInputData))}
            color="secondary"
            classNames={styles.calculateButton}
            disabled={!wellName.length}
          />
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default DataInputSection;
