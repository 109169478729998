import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";

import { isEmail } from "../utils/validations";
import PungoInput from "../../pungo-ui/PungoInput";
import PungoButton from "../../pungo-ui/PungoButton";
import { registerAccount } from "../../store/actions/accountActions";
import CommonTemplate from "components/common/CommonTemplate";

import styles from "./index.module.scss";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [unverifiedCompanyName, setUnverifiedCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkedPassword, setCheckedPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const isConfirmPasswordCorrect = password === checkedPassword;

  const clearForm = () => {
    setName("");
    setUnverifiedCompanyName("");
    setEmail("");
    setPassword("");
    setCheckedPassword("");
  };

  const redirectToLogin = () => navigate("/login");

  const handleOnSubmit = () => {
    dispatch(
      registerAccount(
        { name, unverifiedCompanyName, email, password },
        redirectToLogin
      )
    );
    clearForm();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <CommonTemplate title={`${t("accountPage.signUpLabel")}`} height={500}>
      <PungoInput
        name={`${t("accountPage.fullName")}`}
        value={name}
        onChange={setName}
      />
      <PungoInput
        name={`${t("accountPage.companyName")}`}
        value={unverifiedCompanyName}
        onChange={setUnverifiedCompanyName}
      />
      <PungoInput
        name={`${t("accountPage.emailLabel")}`}
        value={email}
        onChange={setEmail}
        helperText={
          isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
        }
      />
      <PungoInput
        autoComplete={"new-password"}
        name={`${t("accountPage.passwordLabel")}`}
        type="password"
        value={password}
        onChange={setPassword}
      />
      <PungoInput
        autoComplete={"new-password"}
        name={`${t("accountPage.confirmPassword")}`}
        type="password"
        value={checkedPassword}
        onChange={setCheckedPassword}
        helperText={
          !isConfirmPasswordCorrect
            ? `${t("accountPage.mismatchedPasswords")}`
            : undefined
        }
      />
      <div className={styles.adviceSection}>
        <Trans>{t("accountPage.signACompanyUserAdvice")}</Trans>
      </div>
      <PungoButton
        label={`${t("accountPage.signUp")}`}
        onClick={handleOnSubmit}
        color="secondary"
        disabled={
          !isValidEmail ||
          !email.length ||
          !name ||
          !unverifiedCompanyName ||
          !checkedPassword ||
          !password ||
          !isConfirmPasswordCorrect
        }
      />
      <div>
        <div>
          <PungoButton
            label={`${t("accountPage.haveAccountLabel")} ${t("accountPage.loginLabel")}`}
            type="text"
            onClick={redirectToLogin}
            color="secondary"
          />
        </div>
      </div>
    </CommonTemplate>
  );
};

export default LoginPage;
