import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AnimationSection from "./sections/AnimationSection";
import TeamSection from "./sections/TeamSection";
import BenefitsSection from "./sections/BenefitsSection";
import WorkflowSection from "./sections/WorkflowSection";
import OpinionSection from "./sections/OpinionSection";
import PlanByTransactionSection from "components/AstroComponents/PlanByTransactionSection";
import PlanTableSection from "../AstroComponents/PlanTableSection";
import FAQSection from "../AstroComponents/FAQSection";
import ContactForm from "components/AstroComponents/ContactForm";
import ContactUsSection from "../AstroComponents/ContactUsSection";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import PartnersSection from "./sections/PartnersSection";
import PungoButton from "../../pungo-ui/PungoButton";
import { selectSurveyStatus, selectUserName } from "store/slices/auth";

import styles from "./index.module.scss";

const AstroPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoToDemo = () => {
    if (!isLogged) {
      navigate("/signup");
    } else {
      navigate("/plan-select");
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AnimationSection />
      <div className={styles.button}>
        <PungoButton onClick={handleGoToDemo} label={!isLogged ? `${t("askForDemo")}` : `${t("goToPlanSelect")}`} color="secondary" classNames={styles.demoButton} />
      </div>
      <BenefitsSection />
      <WorkflowSection />
      <PlanByTransactionSection />
      <PlanTableSection />
      <OpinionSection />
      <FAQSection />
      <TeamSection />
      <PartnersSection />
      <ContactForm />
      <ContactUsSection />
      <FooterOnePageSection />
    </div>
  );
};

export default AstroPage;
