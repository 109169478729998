import React from "react";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import ContactUsSection from "components/AstroComponents/ContactUsSection";
import ContactForm from "components/AstroComponents/ContactForm";
import styles from "./index.module.scss";

const ContactUsPage: React.FC = () => {

  return (
    <div className={styles.ContactUsPage}>
      <ContactForm />
      <ContactUsSection />
      <FooterOnePageSection />

    </div>
  );
};

export default ContactUsPage;
