import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstanceAWS } from "../../connection";
import { addAPIcall, completeAPIcall } from "../slices/api";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { saveSendingLock } from "store/slices/sendingLock";


const contactFormURL = "https://bsp6bzmy55q4azstlah2kmrxnq0mteae.lambda-url.us-east-1.on.aws";

interface IContactForm {
  email: string;
  phone: string;
  name: string;
  lastName: string;
  company: string;
  message: string;
}

interface IDataToSend extends IContactForm{
  app:string;
}

const APP_LOCATION = window.location.href;


export const sentContactForm =
  (data: IContactForm) =>
  (dispatch: AppDispatch): void => {

    const dataToSent: IDataToSend = {
      ...data,
      app: APP_LOCATION
    }
    dispatch(saveSendingLock(true));
    const localRequest = () => {
      const id = `sentContactForm-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstanceAWS
        .post(`${contactFormURL}`, dataToSent)
        .then((response) => {
          dispatch(displaySnackbarAPISuccess("successMessage.contactFormSubmitted"));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
          dispatch(saveSendingLock(false));
        });
    };

    localRequest();
  };
