import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PungoTable from "pungo-ui/PungoTable";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoXYGraph from "pungo-ui/PungoXYGraph";
import { selectProResults } from "store/slices/plan";
import SelectableTabs from "../../common/SelectableTabs";
import PungoDemoResultsCard from "../../../../../../pungo-ui/PungoDemoResultsCard/index";
import colors from "../../../../../../styles/export.module.scss";
import styles from "./index.module.scss";


const Corrosion: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProResults);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dataX: number[] = [];
  const dataY: number[] = [];

  const labelsForConsolidatedResultsTable = [
    t("proPlan.tableLabels.consolidatedResults.well"),
    t("proPlan.tableLabels.consolidatedResults.date"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedHead"),
    t("proPlan.tableLabels.consolidatedResults.corrosionRiskHead"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedBottom"),
    t("proPlan.tableLabels.consolidatedResults.corrosionRiskBottom"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedMax"),
  ];

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy alto" : "Very high";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  proPlanResults[selectedIndex].corrosionResult.depth.map((value) => {
    return dataY.push(value);
  });

  proPlanResults[selectedIndex].corrosionResult.corrosion.map((value) => {
    return dataX.push(value);
  });

  const xTitle = t("DemoPage.ResultsSection.corrosionSpeedAxis");
  const yTitle = t("DemoPage.ResultsSection.depthAxis");

  const yMax = Math.max(...dataY);
  const nYdiv = Math.floor(yMax / 1000) * 1000 === yMax ? Math.floor(yMax / 1000) : Math.floor(yMax / 1000) + 1;

  const optionsXY = {
    scales: {
      x: {
        responsive: true,
        display: true,
        type: "linear",
        position: "top",
        title: {
          display: true,
          text: xTitle,
          color: "white",
        },
        min: 0,
        // max: xMax,
        // drawTicks: {
        //   display: true,
        //   color: "red",
        // }
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return "white";
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        type: "linear",
        position: "left",
        reverse: true,
        title: {
          display: true,
          text: yTitle,
          color: "white",
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === nYdiv) {
              return "white";
            } else {
              return colors.blue2;
            }
          },
        },
        ticks: {
          stepSize: 1000,
          color: "white",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            return proPlanResults[selectedIndex].wellName;
          },
          label: (ctx: any) => {
            if (proPlanResults[selectedIndex].corrosionResult.corrosion[ctx.dataIndex]) {
              return [
                `${t("DemoPage.ResultsSection.corrosionSpeedAxis")} = ${proPlanResults[selectedIndex].corrosionResult.corrosion[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.depthAxis")} = ${proPlanResults[selectedIndex].corrosionResult.depth[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.pressionAxis")} = ${proPlanResults[selectedIndex].corrosionResult.pressure[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.temperatureAxis")} = ${proPlanResults[selectedIndex].corrosionResult.temperature[ctx.dataIndex].toFixed(2) || 0}`,
                `${t("DemoPage.ResultsSection.corrosionRisk")} = ${translateResults(proPlanResults[selectedIndex].corrosionResult.corrosionRisk[ctx.dataIndex]) || ""}`,
              ];
            }
          },
        },
      },
    },
  };

  const getTableContent = () => (
    <div className={styles.container} id="refResultsProCorrosion">
      <div className={styles.table}>
        <PungoTable
          values={proPlanResults?.map(({ wellName, corrosionResult, date }) => {
            return [
              wellName,
              date,
              corrosionResult.corrosionHead % 1 !== 0 ? corrosionResult.corrosionHead.toFixed(2) : corrosionResult.corrosionHead,
              translateResults(corrosionResult.corrosionRiskHead),
              corrosionResult.corrosionDepth % 1 !== 0 ? corrosionResult.corrosionDepth.toFixed(2) : corrosionResult.corrosionDepth,
              translateResults(corrosionResult.corrosionRiskDepth),
              corrosionResult.corrosionMax % 1 !== 0 ? corrosionResult.corrosionMax.toFixed(2) : corrosionResult.corrosionMax,
            ];
          })}
          labels={labelsForConsolidatedResultsTable}
          height={300}
        />
      </div>
      <div className={styles.tabs}>
        <SelectableTabs labels={proPlanResults?.map(({ wellName }) => wellName)} onSelect={setSelectedIndex} selectedIndex={selectedIndex} />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionSpeed")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headCorrosion") as string}
            result={`${
              proPlanResults[selectedIndex].corrosionResult.corrosionHead % 1 !== 0
                ? proPlanResults[selectedIndex].corrosionResult.corrosionHead.toFixed(2)
                : proPlanResults[selectedIndex].corrosionResult.corrosionHead
            } MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomCorrosion") as string}
            result={`${
              proPlanResults[selectedIndex].corrosionResult.corrosionDepth % 1 !== 0
                ? proPlanResults[selectedIndex].corrosionResult.corrosionDepth.toFixed(2)
                : proPlanResults[selectedIndex].corrosionResult.corrosionDepth
            } MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxCorrosion") as string}
            result={`${
              proPlanResults[selectedIndex].corrosionResult.corrosionMax % 1 !== 0
                ? proPlanResults[selectedIndex].corrosionResult.corrosionMax.toFixed(2)
                : proPlanResults[selectedIndex].corrosionResult.corrosionMax
            } MPY`}
            color={colors.blue4}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionRisk")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskHead)}
            color={getColorForResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskHead)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskDepth)}
            color={getColorForResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskDepth)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskMax)}
            color={getColorForResults(proPlanResults[selectedIndex].corrosionResult.corrosionRiskMax)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionProfile")} </div>
        <div className={styles.xyGraphContainer}>
          <PungoXYGraph dataX={dataX} dataY={dataY} options={optionsXY} />
        </div>
      </div>
    </div>
  );

  return <PungoDemoAccordion title={t("DemoPage.ResultsSection.corrosion")} content={getTableContent()} />;
};

export default Corrosion;
