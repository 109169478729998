import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoResultsCard from "../../../../pungo-ui/PungoDemoResultsCard/index";
import PungoXYGraph from "pungo-ui/PungoXYGraph";
import Warning from "../../../../assets/PungoIcons/Warning.svg";
import Coin from "../../../../assets/PungoIcons/Coin.svg";
import { selectResults } from "store/slices/plan";
import colors from "../../../../styles/export.module.scss";
import styles from "./index.module.scss";

const ResultsSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const dataX1: number[] = [];
  const dataY1: number[] = [];
  const dataX2: number[] = [];
  const dataY2: number[] = [];

  const { wellName, corrosionResult, saturationIndexResult, wellCriticalityResultOutput, chemicalOptimizationResult } = useSelector(selectResults);

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy Alto" : "Very High";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const getEmptyState = () => <div className={styles.emptyState}>{t("DemoPage.ResultsSection.emptyState")}</div>;

  corrosionResult.depth.map((value) => {
    return dataY1.push(value);
  });

  corrosionResult.corrosion.map((value) => {
    return dataX1.push(value);
  });

  const x1Title = t("DemoPage.ResultsSection.corrosionSpeedAxis");
  const y1Title = t("DemoPage.ResultsSection.depthAxis");

  const y1Max = Math.max(...dataY1);
  const nY1div = Math.floor(y1Max / 1000) * 1000 === y1Max ? Math.floor(y1Max / 1000) : Math.floor(y1Max / 1000) + 1;

  const optionsXY1 = {
    scales: {
      x: {
        responsive: true,
        display: true,
        type: "linear",
        position: "top",
        title: {
          display: true,
          text: x1Title,
          color: "white",
        },
        min: 0,
        // max: xMax,
        // drawTicks: {
        //   display: true,
        //   color: "red",
        // }
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return "white";
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        type: "linear",
        position: "left",
        reverse: true,
        title: {
          display: true,
          text: y1Title,
          color: "white",
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === nY1div) {
              return "white";
            } else {
              return colors.blue2;
            }
          },
        },
        ticks: {
          stepSize: 1000,
          color: "white",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title: () => {
            return `${t("DemoPage.DataInputSection.defaultWellName")}`;
          },
          label: (ctx: any) => {
            return [
              `${t("DemoPage.ResultsSection.corrosionSpeedAxis")} = ${corrosionResult?.corrosion[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.depthAxis")} = ${corrosionResult?.depth[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.pressionAxis")} = ${corrosionResult?.pressure[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.temperatureAxis")} = ${corrosionResult?.temperature[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.corrosionRisk")} = ${translateResults(corrosionResult?.corrosionRisk[ctx.dataIndex]) || ""}`,
            ];
          },
        },
      },
    },
  };

  saturationIndexResult.depth.map((value) => {
    return dataY2.push(value);
  });

  saturationIndexResult.calciteSaturationIndex.map((value) => {
    return dataX2.push(value);
  });

  const x2Title = t("DemoPage.ResultsSection.saturationIndex");
  const y2Title = t("DemoPage.ResultsSection.depthAxis");

  const y2Max = Math.max(...dataY2);
  const nY2div = Math.floor(y2Max / 1000) * 1000 === y2Max ? Math.floor(y2Max / 1000) : Math.floor(y2Max / 1000) + 1;

  const optionsXY2 = {
    scales: {
      x: {
        responsive: true,
        display: true,
        type: "linear",
        position: "top",
        title: {
          display: true,
          text: x2Title,
          color: "white",
        },
        min: 0,
        // max: xMax,
        // drawTicks: {
        //   display: true,
        //   color: "red",
        // }
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return "white";
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        type: "linear",
        position: "left",
        reverse: true,
        title: {
          display: true,
          text: y2Title,
          color: "white",
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === nY2div) {
              return "white";
            } else {
              return colors.blue2;
            }
          },
        },
        ticks: {
          stepSize: 1000,
          color: "white",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title: () => {
            return `${t("DemoPage.DataInputSection.defaultWellName")}`;
          },
          label: (ctx: any) => {
            return [
              `${t("DemoPage.ResultsSection.saturationIndex")} = ${saturationIndexResult?.calciteSaturationIndex[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.depthAxis")} = ${saturationIndexResult?.depth[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.pressionAxis")} = ${saturationIndexResult?.pressure[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.temperatureAxis")} = ${saturationIndexResult?.temperature[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.solids")} = ${saturationIndexResult?.solids[ctx.dataIndex].toFixed(2) || 0}`,
              `${t("DemoPage.ResultsSection.incrustationRisk")} = ${translateResults(saturationIndexResult?.encrustationRisk[ctx.dataIndex] || "")}`,
            ];
          },
        },
      },
    },
  };

  const getCorrosionResults = () => (
    <div className={styles.resultsContainer} id="refResultsCorrosion">
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionSpeed")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.headCorrosion") as string} result={`${corrosionResult.corrosionHead} MPY`} color={colors.blue4} />
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.bottomCorrosion") as string} result={`${corrosionResult.corrosionDepth} MPY`} color={colors.blue4} />
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.maxCorrosion") as string} result={`${corrosionResult.corrosionMax} MPY`} color={colors.blue4} />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionRisk")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskHead)}
            color={getColorForResults(corrosionResult.corrosionRiskHead)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskDepth)}
            color={getColorForResults(corrosionResult.corrosionRiskDepth)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskMax)}
            color={getColorForResults(corrosionResult.corrosionRiskMax)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.corrosionProfile")}</div>
        <div className={styles.xyGraphContainer}>
          <PungoXYGraph dataX={dataX1} dataY={dataY1} options={optionsXY1} />
        </div>
      </div>
    </div>
  );

  const getSaturationResults = () => (
    <div className={styles.resultsContainer} id="refResultsSaturation">
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.saturationRate")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.headSaturation") as string} result={saturationIndexResult.saturationIndexHead} color={colors.blue4} />
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.bottomSaturation") as string} result={saturationIndexResult.saturationIndexDepth} color={colors.blue4} />
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.maxSaturation") as string} result={saturationIndexResult.saturationIndexMax} color={colors.blue4} />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.incrustationRisk")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(saturationIndexResult.encrustationRiskHead)}
            color={getColorForResults(saturationIndexResult.encrustationRiskHead)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(saturationIndexResult.encrustationRiskDepth)}
            color={getColorForResults(saturationIndexResult.encrustationRiskDepth)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(saturationIndexResult.encrustationRiskMax)}
            color={getColorForResults(saturationIndexResult.encrustationRiskMax)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.ResultsSection.saturationProfile")}</div>
        <div className={styles.xyGraphContainer}>
          <PungoXYGraph dataX={dataX2} dataY={dataY2} options={optionsXY2} />
        </div>
      </div>
    </div>
  );

  const getChemicalOptimization = () => (
    <div className={styles.resultsContainer} id="refResultsOptimization">
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.OptimizationSection.anticorrosive")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard description={t("DemoPage.OptimizationSection.currentDose") as string} result={`${chemicalOptimizationResult.actualAnticorrosiveDose} GDP`} color={colors.blue4} />
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.recommendedDose") as string}
            result={`${chemicalOptimizationResult.recommendedAnticorrosiveDose} GDP`}
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.inyectionState") as string}
            result={chemicalOptimizationResult.anticorrosiveInjectionStatus}
            color={chemicalOptimizationResult.anticorrosiveInjectionStatus === "Sobre-dosificado" ? colors.red3 : colors.red1}
          />
        </div>
        <div className={styles.graphSection}>
          <img className={styles.graph} src={chemicalOptimizationResult.anticorrosiveInjectionStatus === "Sobre-dosificado" ? Coin : Warning} alt="Warning" />

          <div className={styles.advice}>
            <PungoDemoResultsCard
              result={
                chemicalOptimizationResult.anticorrosiveInjectionStatus === "Sobre-dosificado" ? (
                  <Trans>
                    {t("DemoPage.OptimizationSection.corrosionSaving", {
                      anticorrosiveSaving: chemicalOptimizationResult.potentialAnticorrosiveSavings,
                    })}
                  </Trans>
                ) : (
                  <Trans>{t("DemoPage.OptimizationSection.corrosionRisk")}</Trans>
                )
              }
              color={chemicalOptimizationResult.anticorrosiveInjectionStatus === "Sobre-dosificado" ? colors.red3 : colors.red1}
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>{t("DemoPage.OptimizationSection.antiescale")}</div>
        <div className={styles.results}>
          <PungoDemoResultsCard description={t("DemoPage.OptimizationSection.currentAntiscaleDose") as string} result={`${chemicalOptimizationResult.actualAntiscaleDose} GDP`} color={colors.blue4} />
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.recommendedAntiscaleDose") as string}
            result={`${chemicalOptimizationResult.recommendedAntiscaleDose} GDP`}
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.inyectionAntiscaleState") as string}
            result={chemicalOptimizationResult.antiscaleInjectionStatus}
            color={chemicalOptimizationResult.antiscaleInjectionStatus === "Sobre-dosificado" ? colors.red3 : colors.red1}
          />
        </div>
        <div className={styles.graphSection}>
          <img className={styles.graph} src={chemicalOptimizationResult.antiscaleInjectionStatus === "Sobre-dosificado" ? Coin : Warning} alt="Warning" />
          <div className={styles.advice}>
            <PungoDemoResultsCard
              result={
                chemicalOptimizationResult.antiscaleInjectionStatus === "Sobre-dosificado" ? (
                  <Trans>
                    {t("DemoPage.OptimizationSection.antiscaleSaving", {
                      antiscaleSaving: chemicalOptimizationResult.potentialAntiscaleSavings,
                    })}
                  </Trans>
                ) : (
                  <Trans>{t("DemoPage.OptimizationSection.antiscaleRisk")}</Trans>
                )
              }
              color={chemicalOptimizationResult.antiscaleInjectionStatus === "Sobre-dosificado" ? colors.red3 : colors.red1}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const getWellResults = () => (
    <div className={styles.resultsContainer} id="refResultsCriticality">
      <div className={styles.section}>
        <div className={styles.results}>
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.wellProduction") as string} result={wellCriticalityResultOutput.bopd} color={colors.green2} />
          <PungoDemoResultsCard description={t("DemoPage.ResultsSection.wellCriticalityRate") as string} result={wellCriticalityResultOutput.totalCriticalityIndex} color={colors.blue4} />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.chemicalTreatment") as string}
            result={translateResults(wellCriticalityResultOutput.treatmentPriority)}
            color={getColorForResults(wellCriticalityResultOutput.treatmentPriority)}
          />
        </div>
      </div>
      <div className={styles.section}>
        <Trans>{t("DemoPage.ResultsSection.wellCriticalityText")}</Trans>
      </div>
    </div>
  );
  return (
    <PungoSectionCard title={t("DemoPage.ResultsSection.results")}>
      <div id="refResults">
      {!wellName ? (
        getEmptyState()
      ) : (
        <div className={styles.container}>
          <PungoDemoAccordion title={t("DemoPage.ResultsSection.corrosion")} content={getCorrosionResults()} />
          <PungoDemoAccordion title={t("DemoPage.ResultsSection.saturationRate")} content={getSaturationResults()} />
          <PungoDemoAccordion title={t("DemoPage.ResultsSection.wellCriticality")} content={getWellResults()} />
          <PungoDemoAccordion title={t("DemoPage.OptimizationSection.optimization")} content={getChemicalOptimization()} />
        </div>
      )}
      </div>
    </PungoSectionCard>
  );
};

export default ResultsSection;
