import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserRole, selectUserName, selectSurveyStatus } from "store/slices/auth";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoAccordion from "pungo-ui/PungoAccordion";
import FooterOnePageSection from "components/AstroComponents/FooterOnePageSection";
import PungoButton from "pungo-ui/PungoButton";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import ResultsProSection from "components/DemoPage/sections/ResultsProSection";
import { displaySnackbarAPISuccess } from "store/actions/utils";
import { fetchMockedData } from "store/actions/planActions";
// import { downloadCalculationTemplate } from "store/actions/planActions";
import { selectFetchedProResultsStatus } from "store/slices/plan";
import { Download, UploadFile, Poll } from "@mui/icons-material";
import styles from "./index.module.scss";

const CalculationDemoBatchPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [renderPage, setRenderPage] = useState(false);
  const userRol = useSelector(selectUserRole)[0];
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);

  useEffect(() => {
    dispatch(fetchMockedData());
  }, [dispatch]);

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "free_plan", "pro_plan", "company_user", "company_admin"];

    if (!isLogged) {
      navigate("/login");
    }else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    }else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted]);

  const demoMessage = () => {
    dispatch(displaySnackbarAPISuccess("successMessage.batch-demo"));
  };

  const getInfoTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.text}>{t("CalculatorDemoBatch.information.txt1")}</div>
      <div className={styles.text}>{t("CalculatorDemoBatch.information.txt2")}</div>
    </div>
  );

  const getInstTxt = () => (
    <div className={styles.textContainer}>
      <div className={styles.instructionTitle}>{t("CalculatorOnline.instructions.title")} </div>
      <ol>
        <li> {t("CalculatorDemoBatch.instructions.step01")} </li>
        <li> {t("CalculatorDemoBatch.instructions.step02")} </li>
        <li> {t("CalculatorDemoBatch.instructions.step03")} </li>
      </ol>
      <div className={styles.notes}> {t("CalculatorDemoBatch.instructions.note01")} </div>
    </div>
  );

  const getFileManager = () => (
    <div className={styles.fileManager}>
      <div className={styles.elementBlock}>
        <div className={styles.element}>
          <PungoButton 
          label={`${t("manageProfile.downloadFileButton")}`} 
          // onClick={() => dispatch(downloadCalculationTemplate())}
          onClick={() => demoMessage()} 
          color="primary" 
          icon={<Download />} 
          classNames={styles.firstButton} />
        </div>
        <div className={styles.element}>
          <button onClick={() => demoMessage()} className={styles.emulateFile}>
            <div className={styles.group}>
              <Poll /> <div className={styles.txtInside}>Seleccionar archivo</div>
            </div>
          </button>
        </div>
        <div className={styles.element}>
          <PungoButton label={`${t("manageProfile.uploadFileButton")}`} onClick={() => demoMessage()} color="primary" icon={<UploadFile />} classNames={styles.lastButton} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {renderPage && (
        <>
          <PungoSectionCard title=" " distribution="block">
            <div className={styles.CalculationDemoBatchPage}>
              <div className={styles.title}>{`${t("CalculatorBatch.loadData.title")}`} (Demo)</div>
              <PungoAccordion title={t("PlanSelect.whatIs")} content={getInfoTxt()} noborder={true} />
              <PungoAccordion title={t("PlanSelect.howTo")} content={getInstTxt()} noborder={true} />
              <div className={styles.title2}>{`${t("CalculatorBatch.loadData.fileLoadTitle")}`}</div>
              
              <div className={styles.loadData}> {getFileManager()}</div>
              <div className={styles.results}>{areProResultsFetched ? <ResultsProSection /> : undefined}</div>
            </div>
          </PungoSectionCard>
          <FooterOnePageSection />
          <PungoSelectLanguage />
        </>
      )}
    </>
  );
};

export default CalculationDemoBatchPage;
