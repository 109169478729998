import React from "react";
import { Folder, Speed, Savings, Science, Dataset, Opacity, ViewComfy } from "@mui/icons-material";

interface PungoIconProps {
    icon: string;
    color?: string;
    size?: number;
}


const PungoIcon: React.FC<PungoIconProps> = (props) => {
    const {
        icon,
        color="black",
        size=21
    } = props;

    switch (icon){
        case 'Folder':
            return (<Folder sx={{ fontSize: size, color: color }}/> )
        case 'Speed':
            return (<Speed sx={{ fontSize: size, color: color }}/> )
        case 'Savings':
            return (<Savings sx={{ fontSize: size, color: color }}/> )
        case 'Science':
            return (<Science sx={{ fontSize: size, color: color }}/> )
        case 'Dataset':
            return (<Dataset sx={{ fontSize: size, color: color }}/> )
        case 'Opacity':
            return (<Opacity sx={{ fontSize: size, color: color }}/> )
        default:
            return (<ViewComfy sx={{ fontSize: size, color: color }}/> )
            
    }


};

export default PungoIcon;