import axios, { AxiosRequestConfig } from "axios";
import { store } from "../store";
import i18next from "i18next";

export const axiosInstance = axios.create();
export const axiosInstanceAWS = axios.create();

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "*/*",

      "Accept-Language": i18next.language,
    };

    if (!config.headers) {
      config.headers = headers;
    }

    if (
      !config.headers?.Authorization &&
      !config.url.includes("/session") &&
      !config.url.includes("/astro/account") &&
      !config.url.includes("/account") &&
      !config.url.includes("/login")
    ) {
      let header2: any;
      if (config.url.includes("/calculation/pro/file")) {
        header2 = {
          Accept: "*/*",
          "Accept-Language": i18next.language,
        };
      } else {
        header2 = {
          "Content-Type": "application/json",
          Accept: "*/*",
          "Accept-Language": i18next.language,
        };
      }

      header2.Authorization = `Bearer ${
        store.getState().authManagement.user?.access_token
      }`;
      config.headers = header2;
      return config;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstanceAWS.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    config.headers = {
      "Content-Type": "application/json",
      Accept: "*/*",

      "Accept-Language": i18next.language,
    };

    return config;
  },

  (err) => Promise.reject(err)
);

type Modules =
  | "account"
  | "session"
  | "api"
  | "survey"
  | "energySource"
  | "calculateEnergySource"
  | "calculate"
  | "subscribePlan"
  | "passwordChange"
  | "user"
  | "question"
  | "appUsage"
  | "energies"
  | "mockedDataForProPlan"
  | "mockedDataResults"
  | "calculationFile"
  | "profile"
  | "model"
  | "company"
  | "group"
  | "calculationPro"
  | "resultsReport";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const mocked = {
  account: `${baseURL}/astro/account`,
  survey: `${baseURL}/astro/survey`,
  session: `${baseURL}/session`,
  api: `${baseURL}/api`,
  energySource: `${baseURL}/api/astro/energy-source`,
  calculateEnergySource: `${baseURL}/astro/calculation/simple/energy_sources`,
  calculate: `${baseURL}/astro/calculation/simple/process`,
  subscribePlan: `${baseURL}/astro/plan/`,
  passwordChange: `${baseURL}/core/profile/password-change`,
  user: `${baseURL}/api/astro/user`,
  question: `${baseURL}/api/astro/question`,
  appUsage: `${baseURL}/api/astro/app-usage`,
  energies: `${baseURL}/api/astro/energy-source`,
  mockedDataForProPlan: `${baseURL}/astro/calculation/demo`,
  mockedDataResults: `${baseURL}/astro/calculation/demo/report`,
  calculationFile: `${baseURL}/astro/calculation/pro/file`,
  profile: `${baseURL}/astro/profile`,
  resultsReport: `${baseURL}/astro/calculation/pro/report`,
  calculationPro: `${baseURL}/astro/calculation/pro`,
  model: `${baseURL}/api/astro/well/model`,
  company: `${baseURL}/api/astro/company`,
  group: `${baseURL}/group/astro`,
};

export const getBaseUrlForAPI = (module: Modules): string => mocked[module];
