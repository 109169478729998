import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface Lock {
  currentState: boolean;
}

const initialState: Lock  = {
  currentState: false,
}

export const sendingLockSlice = createSlice({
  name: 'sendingLock',
  initialState,
  reducers: {
    saveSendingLock: (state, action: PayloadAction<boolean>) => {
      state.currentState = action.payload;
    },
  },
})

export const { saveSendingLock } = sendingLockSlice.actions
export const selectSendingLock = (state: RootState) => state.sendingLockManagement.currentState
export default sendingLockSlice.reducer