import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import Coin from "../../../../../../assets/PungoIcons/Coin.svg";
import Warning from "../../../../../../assets/PungoIcons/Warning.svg";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";
import SelectableTabs from "../../common/SelectableTabs";
import PungoDemoResultsCard from "../../../../../../pungo-ui/PungoDemoResultsCard/index";

import styles from "./index.module.scss";
import colors from "../../../../../../styles/export.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const CorrosionOptimization: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProResults);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const labelsForConsolidatedResultsTable = [
    t("proPlan.tableLabels.chemicalOptimization.well"),
    t("proPlan.tableLabels.chemicalOptimization.date"),
    t("proPlan.tableLabels.chemicalOptimization.anticorrosiveActualDosage"),
    t(
      "proPlan.tableLabels.chemicalOptimization.anticorrosiveRecommendedDosage"
    ),
    t("proPlan.tableLabels.chemicalOptimization.anticorrosiveState"),
    t("proPlan.tableLabels.chemicalOptimization.potentialSavings"),
  ];

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy alto" : "Very high";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const totalCorrosionSavings = proPlanResults
    ?.map(
      ({ chemicalOptimizationResult }) =>
        chemicalOptimizationResult.potentialAnticorrosiveSavings
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const getTableContent = () => (
    <div className={styles.container} id="refResultsProOptimizationCorrosion">
      <div className={styles.table}>
        <PungoTable
          values={proPlanResults?.map(
            ({ wellName, chemicalOptimizationResult, date }) => {
              return [
                wellName,
                date,
                chemicalOptimizationResult.actualAnticorrosiveDose % 1 !== 0
                  ? chemicalOptimizationResult.actualAnticorrosiveDose.toFixed(
                      2
                    )
                  : chemicalOptimizationResult.actualAnticorrosiveDose,
                chemicalOptimizationResult.recommendedAnticorrosiveDose % 1 !==
                0
                  ? chemicalOptimizationResult.recommendedAnticorrosiveDose.toFixed(
                      2
                    )
                  : chemicalOptimizationResult.recommendedAnticorrosiveDose,
                chemicalOptimizationResult.anticorrosiveInjectionStatus,
                chemicalOptimizationResult.potentialAnticorrosiveSavings,
              ];
            }
          )}
          labels={labelsForConsolidatedResultsTable}
          height={300}
        />
      </div>
      <div className={styles.image}>
        <img
          className={styles.graph}
          src={totalCorrosionSavings >= 0 ? Coin : Warning}
          alt="Coin"
        />
        <PungoDemoResultsCard
          description={t("proPlan.resultLabel.anticorrosiveSaving") as string}
          result={
            totalCorrosionSavings >= 0
              ? `${totalCorrosionSavings}`
              : (t("proPlan.resultLabel.wellsRisk") as string)
          }
          color={totalCorrosionSavings >= 0 ? colors.green2 : colors.red1}
        />
      </div>
      <div className={styles.tabs}>
        <SelectableTabs
          labels={proPlanResults?.map(({ wellName }) => wellName)}
          onSelect={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.anticorrosiveActualDosage") as string
            }
            result={
              proPlanResults[selectedIndex].chemicalOptimizationResult
                .actualAnticorrosiveDose %
                1 !==
              0
                ? proPlanResults[
                    selectedIndex
                  ].chemicalOptimizationResult.actualAnticorrosiveDose.toFixed(
                    2
                  )
                : proPlanResults[selectedIndex].chemicalOptimizationResult
                    .actualAnticorrosiveDose
            }
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t(
                "DemoPage.ResultsSection.anticorrosiveRecommendedDosage"
              ) as string
            }
            result={
              proPlanResults[selectedIndex].chemicalOptimizationResult
                .recommendedAnticorrosiveDose %
                1 !==
              0
                ? proPlanResults[
                    selectedIndex
                  ].chemicalOptimizationResult.recommendedAnticorrosiveDose.toFixed(
                    2
                  )
                : proPlanResults[selectedIndex].chemicalOptimizationResult
                    .recommendedAnticorrosiveDose
            }
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.anticorrosiveInjectionState") as string
            }
            result={translateResults(
              proPlanResults[selectedIndex].chemicalOptimizationResult
                .anticorrosiveInjectionStatus
            )}
            color={getColorForResults(
              proPlanResults[selectedIndex].chemicalOptimizationResult
                .anticorrosiveInjectionStatus
            )}
          />
        </div>
      </div>
      <div className={styles.image}>
        <img
          className={styles.graph}
          src={
            proPlanResults[selectedIndex].chemicalOptimizationResult
              .potentialAnticorrosiveSavings >= 0
              ? Coin
              : Warning
          }
          alt="Coin"
        />
        <PungoDemoResultsCard
          description={t("DemoPage.ResultsSection.actualState") as string}
          result={
            proPlanResults[selectedIndex].chemicalOptimizationResult
              .potentialAnticorrosiveSavings >= 0
              ? (t("proPlan.resultLabel.wellSaving", {
                  well: proPlanResults[selectedIndex].wellName,
                  saving:
                    proPlanResults[selectedIndex].chemicalOptimizationResult
                      .potentialAnticorrosiveSavings,
                }) as string)
              : (t("proPlan.resultLabel.wellsRisk") as string)
          }
          color={
            proPlanResults[selectedIndex].chemicalOptimizationResult
              .potentialAnticorrosiveSavings >= 0
              ? colors.green2
              : colors.red1
          }
        />
      </div>
    </div>
  );

  return (
    <PungoDemoAccordion
      title={t("proPlan.resultLabel.anticorrosiveOptimization")}
      content={getTableContent()}
    />
  );
};

export default CorrosionOptimization;
